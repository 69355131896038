import styled from '@emotion/styled';

export const PageTemplateContainer = styled.div`
  width: 750px;
  height: 1624px;

  background-color: #f6eddd;

  overflow: hidden;
`;
